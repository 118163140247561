import React from 'react';
import ReactDOM from 'react-dom';

import '@shopify/polaris/build/esm/styles.css';

import App from './App';
import Web from './Web';

import config from './config';

if (config.IN_IFRAME) {
  // @todo replace `react-sticky` and enable strict mode
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Web />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
