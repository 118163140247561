import { RouteComponentProps } from '@reach/router';
import { TextField } from '@satel/formik-polaris';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Card, Modal as PolarisModal } from '@shopify/polaris';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { object, string } from 'yup';
import { Modal as ModalAction } from '@shopify/app-bridge/actions';
import { CategorizationValue } from '../../Components/CategorizationForm/CategorizationInput';

interface GroupValues {
  value: string;
}

interface CategorizationsGroupModalProps {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  groups: CategorizationValue['groups'];
}

export function CategorizationsGroupModal(
  props: CategorizationsGroupModalProps,
) {
  const { id, open, setOpen, groups } = props;

  if (!id) {
    throw new Error('Invalid group id');
  }
  const app = useAppBridge();

  useEffect(() => {
    app.dispatch(ModalAction.data({ id, type: 'INIT' }));
  }, [app, id]);

  const handleSubmit = useCallback(
    (value: GroupValues, helpers: FormikHelpers<GroupValues>) => {
      app.dispatch(
        ModalAction.data({ id, type: 'SUBMIT', value: value.value }),
      );
      helpers.resetForm();
    },
    [app, id],
  );

  const validationSchema = useMemo(
    () =>
      object({
        value: string()
          .label('Group name')
          .trim()
          .min(3)
          .notOneOf(
            groups.map((g) => g),
            'Group already exists',
          )
          .required(),
      }),
    [groups],
  );

  const formik = useFormik({
    initialValues: {
      value: '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    app.dispatch(ModalAction.data({ id, type: 'DIRTY', dirty: formik.dirty }));
  }, [app, formik.dirty, id]);

  return (
    <PolarisModal
      open={open}
      title="Add new group"
      small
      onClose={() => setOpen(false)}
      primaryAction={{
        content: 'Add group',
        onAction: () => formik.submitForm(),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setOpen(false),
        },
      ]}
    >
      <FormikProvider value={formik}>
        <Form>
          <Card sectioned>
            <TextField
              autoComplete="off"
              name="value"
              label="Group name"
              placeholder="Mechanical"
            />
          </Card>
        </Form>
      </FormikProvider>
    </PolarisModal>
  );
}
