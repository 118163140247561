import config from '../config';

import checkStatus from './checkStatus';
import setCompany from './setCompany';
import uploadDiagram from './uploadDiagram';
import createDiagram from './createDiagram';

// Products
import product from './product';
import productLoad from './productLoad';
import productCreate from './productCreate';
import productsLoad from './productsLoad';

// Parts
import partsLoad from './partsLoad';

// Diagram
import diagramLoad from './diagramLoad';
import diagramsLoad from './diagramsLoad';
import diagramUpdate from './diagramUpdate';
import diagramDelete from './diagramDelete';

// Company
import company from './company';
import companyCreate from './companyCreate';
import companyLoad from './companyLoad';
import companyTransfer from './companyTransfer';

// Categorization
import categorization from './categorization';
import { authenticatedFetch } from '../App';

async function getData<R>(path: string): Promise<R> {
  const response = await authenticatedFetch(path);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch data from "${path}", received ${response.status} - ${
        // eslint-disable-line
        response.statusText
      }`,
    );
  }
  // Await here will bubble the error
  return await response.json();
}

export {
  checkStatus,
  setCompany,
  uploadDiagram,
  createDiagram,
  // Products
  product,
  productLoad,
  productCreate,
  productsLoad,
  // Parts
  partsLoad,
  // Diagram
  diagramLoad,
  diagramsLoad,
  diagramUpdate,
  diagramDelete,
  // Company
  company,
  companyCreate,
  companyLoad,
  companyTransfer,
  // Categorization
  categorization,
  authenticatedFetch as serverFetch,
  getData,
};
