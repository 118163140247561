import Home from './Home';
import Setup from './Setup';
import NotFound from './NotFound';

import Parts from './Parts';

import Products from './Products';
import Product from './Product';
import ProductNew from './ProductNew';

import Diagram from './Diagram';
import Diagrams from './Diagrams';
import CreateDiagram from './NewDiagram';

import Company from './Company';

const App = {
  Home,
  Setup,
  NotFound,
  Parts,
  Product,
  Products,
  ProductNew,
  Diagram,
  Diagrams,
  CreateDiagram,
  Company,
};

export default App;
